import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import api from '@hr/api';
import * as dateutil from '@appbase/util/date-helper'
import { createEventBus, removeEventBus } from '@appbase/util/eventBus';

@Component({  components:{DatePicker}})
export default class LeaveManageAdminHeader extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //

    @Prop({ type: Boolean }) isManagerView!: boolean

    @Prop({ type: String }) searchFilter!: string;
    @Prop({ type: String }) searchWord!: string;
    @Prop({ type: Number }) pageNum!: number;
    @Prop({ type: Number }) rowSize!: number;
    @Prop({ type: String, default: '' }) comCode!: string;
   

    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    //
    searchYear: string = '';
    value: string = '';
    years: Array<string> = [];
 
    //
    // ────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────────
    //

    search() {
        var data = {
            searchFilter: this.searchFilter,
            searchWord: this.value,
            searchYear: this.searchYear,
            isManagerReq: this.isManagerView,
            pageNum: 1,
            rowSize: 20,
        }
        this.$emit('load', data);
    }

    getExcelList(){
        var searchFilter = (this.value == '') ? null : this.searchFilter;
        api.leave.downloadManageListExcel(searchFilter, this.value, this.searchYear, this.isManagerView, this.comCode)
    }

    uploadExcel(e:any){
        let file = e.target.files;
        let formData = new FormData();

            if (file[0]) {
                
                formData.append("file", file[0]);
                console.log(formData.get('file'))   ;

                if (formData) {
                    api.leave.uploadManageListExcel(formData, ()=>{return 0}, (err:string)=>{return -1});
                }
            }
    }

    getExcelUploadTemplate(){
        window.open('/cm/gres/templates/ZinusLeaveManageUploadTemplate_V2.xlsx');
    }

    tgrExlUpload(){
        (this.$refs.exluploader as HTMLElement).click();
    }

    created(){
        let _this = this;
        createEventBus(window, 'refreshManageAdminList', _this.search);
        let currentyear = new Date().getFullYear().toString()

        for (let i = -1; i < 2; i++) {
            let year = new Date().getFullYear() - i;
            this.years.push(year.toString());
        }

        this.searchYear = currentyear;

        // api.leave.getLeaveManageAdminYears().then(res=>{
        //     this.years = res as string[]
        //     this.searchYear = this.years[0]
        //     this.years.forEach(e=>{if(e==currentyear){_this.searchYear = currentyear}})
        // })
        // 초기 데이터 조회 할 수 있도록 수정
        this.search();
    }    

    beforeDestroy(){
        let _this = this;
        removeEventBus(window, 'refreshManageAdminList', _this.search);
    }
}
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import LeaveManageAdmin from '@hr/components/LeaveManageAdmin/LeaveManageAdmin.vue';
import { dispatchEventBus } from '@appbase/util/eventBus';
import * as popupHelper from '@appbase/util/popup-helper';

@Component({
    components:{LeaveManageAdmin}
})
export default class LeaveManageAdminList extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Array }) items!: Array<any>
  @Prop({ type: String }) tblHeaderText!: string

  
  onOpenLeaveManagePop(){
    popupHelper.openPopup(`leavemasterModify_New`, "/leavemanageadminPopup/modify",
        {
            id: 0
        },
        {
            width: 700,
            height: 600,
            isFrame: this.isTeams
        },
        ()=>{dispatchEventBus(window, 'refreshManageAdminList', '')} 
      )
  }
}

import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper'
import * as popupHelper from '@appbase/util/popup-helper';
import modalHelper from '@appbase/util/modal-helper';
import { mapGetters } from 'vuex';
import * as appbaseTypes from '@appbase/store/types';
import { dispatchEventBus } from '@appbase/util/eventBus';

@Component({
  computed: mapGetters({
    isTeams: appbaseTypes.TEAMS,
}),
})
export default class LeaveManageAdmin extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //

  @Prop({ type: Object }) item!: any;

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //
  get personId() {
    let personCode: string = this.item.personCode;
    let index: number = personCode.indexOf('_')
    let result: string = personCode.substring(index + 1);

    return result;
  }

  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //
    
  openModifyPopup(){
    popupHelper.openPopup(`leavemasterModify_${this.item.id}`, "/leavemanageadminPopup/modify",
        {
            id: this.item.id
        },
        {
            width: 700,
            height: 600,
            isFrame: this.isTeams
        },
        ()=>{dispatchEventBus(window, 'refreshManageAdminList', '')} 
      )
    
}

  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: W A T C H : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  
    @Watch('item')
    onChangedItem(){
        //this.createdDate = this.item.createdDate.split(' ')[0];
        // this.createdDate = dateutil.uGetDateFormat('yyyy-MM-DD', dateutil.IEDate(this.item.createdDate));
        // this.browser = this.item.app.split('.')[0];
    }

 

   created(){
     this.onChangedItem();
   }
  
}

import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import LeaveManageAdminHeader from '@hr/components/LeaveManageAdmin/LeaveManageAdminHeader.vue';
import LeaveManageAdminList from '@hr/components/LeaveManageAdmin/LeaveManageAdminList.vue';
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue';
import api from '@hr/api';
import * as dateutil from '@appbase/util/date-helper'
import { mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types';


@Component ({
    components: { LeaveManageAdminHeader, LeaveManageAdminList, PageNavigationBar },
    computed: mapGetters({
        user: accountTypes.CURRENT
        }),
})
export default class LeaveManageAdminContainer extends Vue {
  
    @Prop({ type: Boolean }) isManagerView!: boolean
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    // user!: account.TCurrent;

    items: Array<any> = [];
    totalCount: number = 0;
    searchFilter: string = 'name';
    searchWord: string = '';
    pageNum: number = 1;
    rowSize: number = 20;
    // startDate: string =  dateutil.uGetDateFormat('yyyy-MM-DD', new Date(new Date().setMonth(new Date().getMonth() -1)));
    // endDate: string =  dateutil.uGetDateFormat('yyyy-MM-DD', new Date());
    searchYear: string = dateutil.uGetDateFormat('yyyy', new Date());

    comCode: string = '';
    tblHeaderText: string= '전년도'
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: M E T H O D : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    load(data: any) {
        const {searchFilter, searchWord, pageNum, rowSize, searchYear} = data;

        this.searchFilter = searchFilter;
        this.searchWord = searchWord;
        this.pageNum = pageNum;
        this.rowSize = rowSize;
        api.leave.getLeaveManageAdminList(searchFilter, searchWord, pageNum, rowSize, searchYear, this.isManagerView, this.comCode).then((res: any) => {
            if (res) {
                    this.items = res.items;
                    this.totalCount = res.totalCount;
            }
        });
    }

    pageMove(number: number) {
        var data = {
            searchFilter: this.searchFilter,
            searchWord: this.searchWord,
            pageNum: number,
            isManagerReq: this.isManagerView,
            rowSize: this.rowSize,
            searchYear: this.searchYear
        }
        this.load(data);
    }

    selectType(value:any){
        this.searchFilter = value
    }

    selectYear(value:any){
        
        this.searchYear = value
        //alert('selectyear'+value);
    }
    //
    // ──────────────────────────────────────────────────────── I ──────────
    //   :::::: L I F E C Y L E : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────────────
    //

    @Watch('isManagerView')
    onQueryChanged(){
        this.items=[];
        this.totalCount = 0;
        
        // this.load(data);
        this.setPage({title:this.$t('휴가현황관리')});
        //this.created()
    }

    @Watch('$route.query')
    onQueryStringChanged(){
        const { comcode } = this.$route.query
        // console.log('onQueryStringChanged', comcode)
        if(comcode != undefined){
            this.comCode = comcode.toString();
            this.tblHeaderText = comcode=='c04'?'W_ETC':'전년도'
        }

        this.pageMove(1);
    }

    mounted() {

    }

    created() {
        // var data = {
        //     searchFilter: this.searchFilter,
        //     searchWord: this.searchWord,
        //     pageNum: this.pageNum,
        //     rowSize: this.rowSize,
        //     searchYear: this.searchYear,
        //     isManagerReq: this.isManagerView
        // }

        const { comcode } = this.$route.query
        // console.log('comcode', comcode)
        if(comcode != undefined){
            this.comCode = comcode.toString();
            this.tblHeaderText = comcode=='c04'?'W_ETC':'전년도'
        }
        
        this.setPage({title:this.$t('휴가현황관리')});
        //this.load(data);
    }
}

import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LeaveManageAdminContainer from '@hr/containers/LeaveManageAdminContainer.vue'
@Component({
    components:{
        LeaveManageAdminContainer
    }
})
export default class LeaveManageAdminView extends Vue {
    @Prop({ type: Boolean }) isManagerView!: boolean
}
